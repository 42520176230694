<template>
  <div class="widgetContainer widgetContainer--center bg-white accountTypeSelection">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <BrandImg
          class="welcome__logo"
          src="logos/Logo.svg"
          alt="logo" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <p class="intro_txt">
        {{ descText }}
      </p>
      <p class="txt_bold">
        {{ $t('account_selection_option_title') }}
      </p>
      <div class="accountList__list">
        <div>
          <ul>
            <li @click="selectAccountType('personalChecking')">
              <div class="details">
                <p class="accountName">
                  {{ $t('personal_checking_account') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right back-action" />
              </div>
            </li>
            <li @click="selectAccountType('businessChecking')">
              <div class="details">
                <p class="accountName">
                  {{ $t('business_checking_account') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right back-action" />
              </div>
            </li>
            <li v-if="false" @click="selectAccountType('personalbusinessChecking')">
              <div class="details">
                <p class="accountName">
                  {{ $t('personal_business') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right back-action" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { getBrandKey } from '@/utils/env';
import BrandImg from '@/components/BrandImg.vue';
export default {
  components: {
    BrandImg
  },
  methods: {
    ...mapMutations('kyc', ['setSelectedAccountType']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    ...mapMutations('account', ['updateCreateAccountData']),
    selectAccountType(type) {
      this.setSelectedAccountType(type);
      setTimeout(() => {
        if (type === 'personalChecking') {
          this.updateCreateAccountData({
            businessId: null,
            type: 'personalChecking'
          });
          this.$router.push('/account/setup');
        } else {
          this.getBusinessList();
        }
      }, 0);
      console.log(type);
    },
    /**
     * get business list
     */
    getBusinessList() {
      const loader = this.showLoader();
      this.$store
        .dispatch('kyb/listAllBusiness')
        .then((r) => {
          if (r.total === 0) {
            this.$router.push('/kyb/businessDetails');
          } else {
            /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']}*/
            const businessList = r.data;
            this.setSelectedBusiness(businessList[0]);

            if (businessList[0] && businessList[0].kyb) {
              switch (businessList[0].kyb.status) {
              case 'approved':
                if (businessList[0].disclosureStatus === 'completed') {
                  this.updateCreateAccountData({
                    businessId: businessList[0].id,
                    type: 'businessChecking'
                  });
                  //account page
                  setTimeout(() => {
                    this.$router.push('/account/setup');
                  }, 0);
                } else {
                  this.$router.push('/kyb/businessStatus');
                }
                break;
              case 'notStarted':
                this.$router.push('/kyb/businessDetails');
                break;
              default:
                this.$router.push('/kyb/businessStatus');
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  },
  computed: {
    descText() {
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key+'_account_selection_desription_text') : 'account_selection_desription_text')
      
    }
  }
};
</script>
<style lang="scss">
.accountTypeSelection {

  @media (max-width: 768px) {
    position: static;
  }
  
  .welcome__logo {
    max-width: 250px;
    margin: 90px auto 46px auto;
    max-height: 50px;
  }
  .intro_txt {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-bottom: 40px;
  }
  .navigationHeader {
    padding-left: 30px;
    border: none;
    .title {
      left: 0;
      transform: unset;
    }
  }
  .txt_bold {
    font-weight: 600;
    text-align: center;
    margin-bottom: 56px;
  }
  .widgetContainer__body {
    padding: 30px;
    padding-top: 30px;
    position: relative;
    overflow-y: auto;
  }
  ul {
    li {
      background: #fff;
      border-radius: var(--radius);
      padding: 19px 16px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border: 1px solid #edeff1;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        border-color: var(--branding);
      }

      .details {
        margin: 0 auto 0 0;
        padding-right: 8px;

        .accountName {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .action {
        cursor: pointer;
        font-size: 14px;
      }
      .selected,
      .unselected {
        font-size: 20px;
      }

      .selected {
        display: none;
      }

      &.selected {
        cursor: pointer;
        border-color: var(--branding);

        .initialsInfo {
          background-color: var(--branding);
        }

        .unselected {
          display: none;
        }

        .selected {
          display: block;
        }
      }
    }
  }
}
</style>
